import { ChangeDetectionStrategy, Component, effect, signal, WritableSignal } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { SurveyService } from '@stsm/global/composite/services/survey.service';
import { SurveyStepType } from '@stsm/global/models/survey';
import { TranslatePipe } from '@stsm/i18n/pipes/translate.pipe';
import { GlobalLocalStorageKey } from '@stsm/shared/enums/global-localstorage-key';
import { BrowserStorageService } from '@stsm/shared/services/browser-storage/browser-storage.service';
import { SentryService } from '@stsm/shared/services/sentry.service';
import { SpinnerComponent } from '@stsm/ui-components/spinner';

import { AnalyticsService } from '../../../shared/services/analytics.service';
import { PlatformService } from '../../../shared/services/platform.service';

// Survey: "New payment methods fake door"
const SURVEY_ID = '18oH5Kvx169ASxCcGp-406rjN-kRNaJpylwjtCIZR9Sw';

interface PaymentProvider {
  name: string;
  value: string;
  logo: string;
  color: string;
  trackingValue: string;
  borderColor?: string;
  isHidden?: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-payment-provider-fake-door',
  imports: [TranslatePipe, SpinnerComponent],
  templateUrl: './payment-provider-fake-door.component.html',
  styleUrl: './payment-provider-fake-door.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentProviderFakeDoorComponent {
  protected readonly isIOS: boolean = this._platformService.isIOS;
  protected readonly isAndroid: boolean = this._platformService.isAndroid;

  protected readonly hasAlreadySubmitted: WritableSignal<boolean> = signal(
    this._browserStorageService.getItemLocalStorage(GlobalLocalStorageKey.HAS_ANSWERED_PAYMENT_SURVEY) ?? false,
  );

  protected isLoading: WritableSignal<boolean> = signal(false);

  protected paymentProviders: PaymentProvider[] = [
    {
      name: 'Apple Pay',
      value: 'Apple Pay',
      trackingValue: 'apple_pay',
      logo: 'assets/shared/logos/apple_pay_white.svg',
      color: '#000',
      isHidden: this.isAndroid,
    },
    {
      name: 'Google Pay',
      value: 'Google Pay',
      trackingValue: 'google_pay',
      logo: 'assets/shared/logos/google_pay.svg',
      color: '#FFF',
      borderColor: '#3C4043',
      isHidden: this.isIOS,
    },
    {
      name: 'Klarna',
      value: 'Klarna',
      trackingValue: 'klarna',
      logo: 'assets/shared/logos/klarna.svg',
      color: '#FEB4C7',
    },
  ];

  constructor(
    private readonly _surveyService: SurveyService,
    private readonly _browserStorageService: BrowserStorageService,
    private readonly _platformService: PlatformService,
    private readonly _analyticsService: AnalyticsService,
    private readonly _sentryService: SentryService,
  ) {
    effect(() => {
      this._browserStorageService.setItemLocalStorage(GlobalLocalStorageKey.HAS_ANSWERED_PAYMENT_SURVEY, this.hasAlreadySubmitted());
    });
  }

  protected submitPaymentProvider(paymentProvider: PaymentProvider): void {
    if (this.hasAlreadySubmitted() || this.isLoading()) {
      return;
    }

    this.isLoading.set(true);

    this._analyticsService.trackEvent({
      action: 'premium_web_payment_method_click',
      properties: {
        type: paymentProvider.trackingValue,
      },
    });

    this._surveyService
      .submitSurveyResponse(SURVEY_ID, [
        {
          type: SurveyStepType.MULTIPLE_CHOICE,
          gid: 942259208,
          question: 'payment methods',
          choicesResponse: {
            choices: [
              {
                displayedText: paymentProvider.value,
                gText: paymentProvider.value,
                isSelected: true,
              },
            ],
            isSingleChoice: true,
          },
        },
      ])
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.isLoading.set(false);
          this.hasAlreadySubmitted.set(true);
        },
        error: (error: unknown) => {
          console.error(error);

          this._sentryService.reportToSentry('Error when submitting the payment survey', error);
        },
      });
  }
}
