import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash-es';
import { catchError, switchMap, tap, throwError } from 'rxjs';

import { PremiumModalCallSource } from '@stsm/analytics';
import { AuthStore } from '@stsm/auth/data/auth-store.service';
import { TranslationService } from '@stsm/i18n/services/translation.service';
import { PremiumPlan } from '@stsm/premium/models/premium-plan';
import { ProjectedRevenueData } from '@stsm/premium/models/purchase-result';
import { SimpleDialogService } from '@stsm/ui-components/dialogs/simple-dialog/simple-dialog.service';
import { LoadingService } from '@stsm/ui-components/loading-dialog/loading.service';
import { PaymentProvider } from '@stsm/user/models/payment-provider';

import { environment } from '../../../../environments/environment';
import { AnalyticsService } from '../../../shared/services/analytics.service';
import { PremiumService } from '../../../shared/services/premium.service';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let paypal: any;

@UntilDestroy()
@Component({
  selector: 'app-paypal',
  templateUrl: './paypal.component.html',
  styleUrls: ['./paypal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaypalComponent implements OnInit {
  @Input({ required: true }) plan!: PremiumPlan;
  @Input() source!: PremiumModalCallSource;

  @Output() readonly transactionCompleted: EventEmitter<void> = new EventEmitter<void>();
  @Output() readonly transactionInProcess: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private readonly _analyticsService: AnalyticsService,
    private readonly _loadingService: LoadingService,
    private readonly _premiumService: PremiumService,
    private readonly _translationService: TranslationService,
    private readonly _simpleDialogService: SimpleDialogService,
    private readonly _authStore: AuthStore,
  ) {}

  ngOnInit(): void {
    if (this.plan) {
      this._setupSubscriptionButtons(this.plan);
    }
  }

  private _setupSubscriptionButtons(plan: PremiumPlan): void {
    // see https://developer.paypal.com/sdk/js/reference/
    paypal
      .Buttons({
        style: {
          color: 'blue',
          shape: 'pill',
          label: 'pay',
          height: 40,
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        createSubscription: (_: any, actions: any) => {
          this._analyticsService.trackEvent({
            action: 'premium_plan_purchase_init',
            properties: {
              productID: plan.productID,
              source: this.source,
            },
          });

          return actions.subscription.create({
            plan_id: plan.productID,
            custom_id: this._authStore.userId,
          });
        },
        onClick: (data: { fundingSource: string }) => {
          this._analyticsService.trackEvent({
            action: 'premium_web_payment_method_click',
            properties: {
              type: data.fundingSource,
            },
          });
        },
        onApprove: (data: { subscriptionID: string; orderID: string }) => {
          this._loadingService.showLoading(this._translationService.get('PREMIUM.PLANS.ACTIVATING'));
          this.transactionInProcess.emit(true);

          this._onSubscriptionApproved(plan.productID, data.subscriptionID, data.orderID);
        },
        onCancel: () => {
          // Show a cancel page, or return to cart
          this._analyticsService.trackEvent({ action: 'premium_plans_purchase_plan_cancel' });
        },
      })
      .render('#paypal-button-container');
  }

  private _onSubscriptionApproved(productId: string, subscriptionId: string, orderId: string): void {
    this._analyticsService.trackEvent({ action: 'premium_validate_receipt' });
    this._premiumService
      .validateSubscriptionReceipt(subscriptionId, orderId)
      .pipe(
        switchMap((projectedRevenueData: ProjectedRevenueData) => {
          this._analyticsService.trackEvent({
            action: 'premium_receipt_validation_finish',
            properties: {
              provider: PaymentProvider.PAYPAL,
              success: true,
            },
          });

          this._analyticsService.trackEvent({
            action: 'premium_plans_purchase_plan_success',
            properties: {
              subscriptionId: !isNil(subscriptionId),
              orderID: !isNil(orderId),
              productId,
              source: this.source,
              provider: PaymentProvider.PAYPAL,
            },
            revenueProperties: {
              revenue: projectedRevenueData.projectedRevenue,
              revenueType: 'purchase',
              isProjectedRevenue: true,
            },
          });

          this._analyticsService.setAmplitudeUserProperty('premium_conversion_flow', this.source);
          this._loadingService.hideLoading();

          return this._premiumService.refreshPremiumInfo();
        }),
        tap(() => {
          this.transactionInProcess.emit(false);
          this.transactionCompleted.emit();
        }),
        catchError((err: Error) => {
          this._analyticsService.trackEvent({
            action: 'premium_receipt_validation_finish',
            properties: {
              provider: PaymentProvider.PAYPAL,
              success: false,
            },
          });
          this._loadingService.hideLoading();
          this._simpleDialogService.showError('PREMIUM.PLANS.ERROR_VALIDATION');

          if (!environment.production) {
            console.error(err);
          }

          return throwError(() => err);
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
