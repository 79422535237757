<button uiIconButton tertiary class="close-button" [disabled]="isTransactionInProcess" (click)="onCloseDialog()">
  <i class="app-icon-close"></i>
</button>

<div class="content">
  @if (premiumPlan()) {
    <p class="title">{{ "PREMIUM.PLANS.PREMIUM_MEMBERSHIP" | translate }}</p>
    @if (!usedFreeTrial()) {
      <p class="subtitle" data-cy="payment-dialog-subtitle-free-trial-info" [innerHTML]="priceInfo()"></p>
    }
    <p class="subtitle">({{ premiumPlan().overallPrice }} / {{ premiumPlan().durationKey | translate }})</p>
  }

  <div class="button-wrapper">
    <div class="payment-wrapper" [class.inactive]="!premiumPlan()">
      <app-paypal
        [plan]="premiumPlan()"
        [source]="source"
        (transactionCompleted)="onTransactionCompleted()"
        (transactionInProcess)="onTransactionInProcess($event)"
      />
    </div>

    @if (isFakeDoorTreatment()) {
      <ui-or-separator />
      <app-payment-provider-fake-door />
    }
  </div>
</div>
