<div class="payment-providers-wrapper">
  @for (provider of paymentProviders; track provider.value) {
    @if (!provider.isHidden) {
      <button
        class="payment-provider-button"
        [style.background-color]="provider.color"
        [style.border-color]="provider.borderColor"
        [disabled]="isLoading() || hasAlreadySubmitted()"
        (click)="submitPaymentProvider(provider)"
      >
        <img [src]="provider.logo" [alt]="provider.name" />
      </button>
    }
  }
</div>

<div class="infos">
  @if (isLoading()) {
    <ui-spinner />
  }
  @if (hasAlreadySubmitted()) {
    <span class="coming-soon-badge">{{ "PREMIUM.FAKE_DOOR.COMING_SOON" | translate }}</span>
    <span class="heading">{{ "PREMIUM.FAKE_DOOR.HEADING" | translate }}</span>
  }
</div>
