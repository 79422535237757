import { JsonObject } from '@stsm/shared/types/json-object';
import { KeyMapping } from '@stsm/shared/types/key-mapping';
import { toJsonHelper } from '@stsm/shared/util/json-util';

import { PaymentProvider } from './payment-provider';

export const premiumInfoKeyMapping: KeyMapping<PremiumInfo> = {
  provider: 'provider',
  endDate: 'end_date',
  activeSubscriptionID: 'subscriptionId',
  cancelled: 'cancelled',
  isPremium: 'is_premium',
  adsDisabled: 'ads_disabled',
  usedFreeTrial: 'used_free_trial',
  isTrial: 'is_trial',
  activePurchaseToken: 'active_purchase_token',
  mondiaProvider: 'mondia_provider',
  hasFreeStudysetsAndSearch: 'free_studysets_and_search',
};

export interface PremiumInfo {
  readonly provider: PaymentProvider | undefined;
  readonly endDate: string | undefined;
  readonly activeSubscriptionID: string | undefined;
  readonly cancelled: boolean;
  readonly isPremium: boolean;
  readonly adsDisabled: boolean;
  readonly usedFreeTrial: boolean;
  readonly isTrial: boolean;
  readonly activePurchaseToken: string | undefined;
  readonly mondiaProvider: string | undefined;
  readonly hasFreeStudysetsAndSearch: boolean;
}

export const PREMIUM_INFO_MOCK: PremiumInfo = {
  provider: undefined,
  endDate: '',
  activeSubscriptionID: '',
  cancelled: false,
  isPremium: false,
  adsDisabled: false,
  usedFreeTrial: false,
  isTrial: false,
  activePurchaseToken: undefined,
  mondiaProvider: undefined,
  hasFreeStudysetsAndSearch: false,
};

export class PremiumInfoSerializer {
  static fromJson(json: JsonObject): PremiumInfo {
    // if premium is false, other fields have to be ignored
    const isPremium = (json[premiumInfoKeyMapping.isPremium] as boolean) ?? false;

    return {
      provider: isPremium ? (json[premiumInfoKeyMapping.provider] as PaymentProvider | undefined) : undefined,
      endDate: isPremium ? ((json[premiumInfoKeyMapping.endDate] as string) ?? undefined) : undefined,
      activeSubscriptionID: isPremium ? ((json[premiumInfoKeyMapping.activeSubscriptionID] as string) ?? undefined) : undefined,
      cancelled: (json[premiumInfoKeyMapping.cancelled] as boolean) ?? false,
      isPremium,
      adsDisabled: (json[premiumInfoKeyMapping.adsDisabled] as boolean) ?? false,
      usedFreeTrial: (json[premiumInfoKeyMapping.usedFreeTrial] as boolean) ?? false,
      isTrial: (json[premiumInfoKeyMapping.isTrial] as boolean) ?? false,
      activePurchaseToken: isPremium ? ((json[premiumInfoKeyMapping.activePurchaseToken] as string) ?? undefined) : undefined,
      mondiaProvider: (json[premiumInfoKeyMapping.mondiaProvider] as string) ?? undefined,
      hasFreeStudysetsAndSearch: (json[premiumInfoKeyMapping.hasFreeStudysetsAndSearch] as boolean) ?? false,
    };
  }

  static toJson(premiumInfo: PremiumInfo): object {
    return toJsonHelper({ entity: premiumInfo, keyMapping: premiumInfoKeyMapping });
  }
}
